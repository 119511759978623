import { Link } from 'react-router-dom';
import './Logo.css';
import logo from './tl-logo.png';

const Logo: React.FC = () => {

  return (
    <Link to="/">
      <img src={logo} className="nav-logo" alt="logo" />
    </Link>
  );
};

export default Logo;