import { Link, Switch, Route } from 'react-router-dom';
import { Layout, Button } from 'antd';
import Logo from '../../components/logos/Logo/Logo'
import routes from "./../../routes";

import './Base.css';

import footerString from '../../data/site-info/footer-data';

const Base: React.FC = () => {
  const { Header, Footer, Content } = Layout;

  const loc = window.location.pathname;
  console.log(loc);

  const switchRoutes = (
    <Switch>
      {routes.map((prop, key) => {
        if (prop.layout === '') {
          return (
            <Route
              path={prop.layout + prop.path}
              component={prop.component}
              key={key}
            />
          );
        }
      })}
    </Switch>
  );

  return (
    <Layout>
      <Header 
        className="base-header"
      >
        <Logo />
        { !loc.includes('login') &&
          <Link to="/login">
            <Button
              type="primary"
              className="login-button"
            >
              Login
            </Button>
          </Link>
        }
      </Header>
      <Content className="base-content">
          <div style={{ padding: 24, minHeight: "100vh" }}>
            {switchRoutes}
          </div>
      </Content>
      <Footer className="base-footer">{footerString}</Footer>
    </Layout>
  );
};

export default Base;