import Dashboard from './pages/admin/Dashboard/Dashboard';
import UserManagement from './pages/admin/UserManagement/UserManagement';
import Login from './pages/base/Login/Login';
import Error from './pages/base/Error/Error';

import { UploadOutlined, UserOutlined, VideoCameraOutlined } from '@ant-design/icons';

const routes = [
    {
        path: "/dashboard",
        name: "Dashboard",
        icon: UploadOutlined,
        component: Dashboard,
        layout: "/admin",
    },
    {
        path: "/usermanagement",
        name: "UserManagement",
        icon: VideoCameraOutlined,
        component: UserManagement,
        layout: "/admin",
    },
    {
        path: "/login",
        name: "Login",
        icon: UserOutlined,
        component: Login,
        layout: "",
    },
    {
        path: "/error",
        name: "Error",
        icon: UserOutlined,
        component: Error,
        layout: "",
    },
];

export default routes;

