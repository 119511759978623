import { Form, Button, Input, message } from 'antd';
import PropTypes from 'prop-types';
import * as queries from '../../../data/api/queries';
import './AddUserForm.css';

interface Props {
  onOk: () => void
}

const AddUserForm: React.FC<Props> = (props) => {
  const [form] = Form.useForm();

  const onFinish = (values: Record<string, unknown>) => {
    console.log(values.name);
    console.log(values.email);
    console.log(values.password);

    const query = queries.addUserMutation;
    const { headers } = queries;
    headers['Authorization'] = localStorage.getItem('Authorization') ?? '';
    const variables = {
      name: values.name,
      email: values.email,
      password: values.password,
      organisationId: localStorage.getItem('OrganisationId'),
      role: 'user',
    }

    form.resetFields();

    queries.sendQueryReturnObjObj(query, variables, headers)
    .then((data) => {
      console.log(data);
      if ('errors' in data) {
        props.onOk();
        if (data.errors.message) {
          message.error(data.errors.message);
        } else if (data.errors[0].message) {
          message.error(data.errors[0].message);
        } else {
          message.error('Data unavailable');
        }
      } else {
        props.onOk();
        console.log(data.data);
        message.success(data.data.addUser.name + ' was created successfully');
      }
    })
  }

  return (
    <Form
      form={form}
      key='add-user-form'
      className='add-user-form'
      layout="vertical"
      name='add-user-form'
      onFinish={onFinish}
    >
      <Form.Item
        label='Name'
        name='name'
        rules={[{ required: true, message: "Please input the user's name" }]}
      >
        <Input placeholder='Enter name'/>
      </Form.Item>

      <Form.Item
        label='Email'
        name='email'
        rules={[{ required: true, message: "Please input the user's email address" }]}
      >
        <Input placeholder='Enter email'/>
      </Form.Item>

      <Form.Item
        label='Password'
        name='password'
        rules={[{ required: true, message: "Please input the user's password" }]}
      >
        <Input placeholder='Enter password'/>
      </Form.Item>

      <Form.Item>
        <Button className="login-form-btn" type="primary" htmlType="submit">
          Submit
        </Button>
      </Form.Item>
    </Form>
  );
};

AddUserForm.propTypes = {
  onOk: PropTypes.func.isRequired,
};

export default AddUserForm;