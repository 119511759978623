import React from 'react';
import ReactDOM from 'react-dom';
//import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { HashRouter as Router, Route, Switch } from "react-router-dom";
import { AuthContextProvider } from './data/session/UserSession/UserSession';
import reportWebVitals from './reportWebVitals';

import Admin from './layouts/Admin/Admin';
import Base from './layouts/Base/Base';

import './index.css';

ReactDOM.render(
  <AuthContextProvider>
    <React.StrictMode>
      <Router>
        <Switch>
          <Route path="/admin" component={Admin} />
          <Route path="/" component={Base} />
        </Switch>
      </Router>
    </React.StrictMode>
  </AuthContextProvider>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
