export function convertTimestamp(timestamp: number): string {
  const d = new Date(timestamp), // Convert the passed timestamp to milliseconds
    yyyy = d.getFullYear(),
    mm = ('0' + (d.getMonth() + 1)).slice(-2),  // Months are zero based. Add leading 0.
    dd = ('0' + d.getDate()).slice(-2),         // Add leading 0.
    min = ('0' + d.getMinutes()).slice(-2),     // Add leading 0.
    hh = d.getHours();
  let h = hh,
    ampm = 'AM';

  if (hh > 12) {
      h = hh - 12;
      ampm = 'PM';
  } else if (hh === 12) {
      h = 12;
      ampm = 'PM';
  } else if (hh == 0) {
      h = 12;
  }

  // ie: 2014-03-24, 3:00 PM
  const time12 = yyyy + '-' + mm + '-' + dd + ', ' + h + ':' + min + ' ' + ampm;
  const time24 = yyyy + '-' + mm + '-' + dd + ', ' + hh + ':' + min;
  return time24;
}

export function convertTimestampShort(timestamp: number): string {
  const d = new Date(timestamp), // Convert the passed timestamp to milliseconds
    min = ('0' + d.getMinutes()).slice(-2),     // Add leading 0.
    hh = d.getHours();
  let h = hh,
    ampm = 'AM';

  if (hh > 12) {
      h = hh - 12;
      ampm = 'PM';
  } else if (hh === 12) {
      h = 12;
      ampm = 'PM';
  } else if (hh == 0) {
      h = 12;
  }

  // ie: 3:00 PM
  const time = h + ':' + min + ' ' + ampm;
  return time;
}

export function convertTimestampDay(timestamp: number): string {
  const d = new Date(timestamp), // Convert the passed timestamp to milliseconds
    yyyy = d.getFullYear(),
    mm = ('0' + (d.getMonth() + 1)).slice(-2),  // Months are zero based. Add leading 0.
    dd = ('0' + d.getDate()).slice(-2);         // Add leading 0.
    // min = ('0' + d.getMinutes()).slice(-2),     // Add leading 0.
  //   hh = d.getHours();
  // let h = hh,
  //   ampm = 'AM';

  // if (hh > 12) {
  //     h = hh - 12;
  //     ampm = 'PM';
  // } else if (hh === 12) {
  //     h = 12;
  //     ampm = 'PM';
  // } else if (hh == 0) {
  //     h = 12;
  // }

  const time = yyyy + '-' + mm + '-' + dd;
  return time;
}

export function getYesterdayTimestamp(): string {
  const yesterday = new Date(new Date().getTime() - (24 * 60 * 60 * 1000));
  const date = yesterday.getFullYear() + '-' + (yesterday.getMonth() + 1) + '-'+yesterday.getDate();
  const time = yesterday.getHours() + ":" + yesterday.getMinutes() + ":" + yesterday.getSeconds();
  const dateTime = date+' '+time;
  return dateTime;
}

export function getYesterdayUnixTimestamp(): Date {
  const yesterday = new Date(new Date().getTime() - (24 * 60 * 60 * 1000));
  // const date = yesterday.getFullYear() + '-' + (yesterday.getMonth() + 1) + '-'+yesterday.getDate();
  // const time = yesterday.getHours() + ":" + yesterday.getMinutes() + ":" + yesterday.getSeconds();
  // const dateTime = date+' '+time;
  return yesterday;
}

export function getTwoWeeksAgoTimestamp(): string {
  const yesterday = new Date(new Date().getTime() - (14 * 24 * 60 * 60 * 1000));
  const date = yesterday.getFullYear() + '-' + (yesterday.getMonth() + 1) + '-'+yesterday.getDate();
  const time = yesterday.getHours() + ":" + yesterday.getMinutes() + ":" + yesterday.getSeconds();
  const dateTime = date+' '+time;
  return dateTime;
}