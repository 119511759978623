export function validateEmailIds(emails: string):boolean {
  const regex = /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i;

  const result = emails.replace(/\s/g, "").split(/,|;/);
    for(let i = 0;i < result.length;i++) {
      if(!regex.test(result[i])) {
        return false;
      }
    }
    return true;
}

export function validateTelegramChatIDs(chatIDs: string):boolean {
  const regex = /^[-+]?\d+$/;

  const result = chatIDs.replace(/\s/g, "").split(/,|;/);
    for(let i = 0;i < result.length;i++) {
      if(!regex.test(result[i])) {
        return false;
      }
    }
    return true;
}