/* eslint-disable @typescript-eslint/ban-ts-comment */
import { Tabs } from 'antd';
import { Line } from '@ant-design/charts';
import PropTypes from 'prop-types';
import * as utilities from '../../data/utilities/utilities';


const SensorDataChart: React.FC<{ sensorData: Record<string,Record<string, unknown>[]>, type: string }> = ({sensorData, type}) => {
  const { TabPane } = Tabs;

  const startTime =  utilities.getYesterdayUnixTimestamp();
  const verifyDate = utilities.convertTimestamp(Number(startTime));

  let min = -25;
  let max = 40;
  let threshold = 0;

  if (type === 'Chiller') {
    min = 0;
    max = 25;
    threshold = 5;
  } else if (type === 'Freezer') {
    max = 0;
    threshold = -15;
  } else if (type === 'Room') {
    min = 15;
    threshold = 30;
  }

  const data = sensorData.getSensorDataByIdByTime;
  data.forEach((element) => {
    element.key = element["nodeId"];
    const timestamp = Number(element['measuredtime']);
    element['timestamp'] = utilities.convertTimestamp(timestamp);
    element['temperature'] = Number(element['temperature']);
    element['humidity'] = Number(element['humidity']);
  });

  const config = {
    height: 300,
    data : data,
    xField : 'measuredtime', 
    xAxis: {
      label: {
        formatter: (measuredtime: string) => {
          return utilities.convertTimestampDay(Number(measuredtime));
        },
      },
      tickCount: 6,
    },
    point: {
      size: 2,
      shape: 'circle',
      style: {
        fill: 'white',
        stroke: '#5B8FF9',
        lineWidth: 1,
      },
    },
    tooltip: {
      title: (measuredtime: string) => {
        return utilities.convertTimestamp(Number(measuredtime));
      },
    },
    slider: {
      formatter: (measuredtime: string) => {
        return utilities.convertTimestamp(Number(measuredtime));
      },
    },
  }

  const configTemperature = { 
    ...config,
    yField : 'temperature',
    yAxis: {
      tickCount: 5,
      label: {
        formatter: (val: string | number) => {
          return (+val).toFixed(1) + String.fromCharCode(176) + 'C';
        },
      },
      min: min,
      max: max,
    },
    lineStyle: {
      // stroke: '#fff',
      stroke: '#1890FF',
      lineWidth: 1,
    },
    annotations : [ 
      // {
      //   type : 'regionFilter',
      //   top: true, 
      //   // start : [ threshold , 'max' ],  
      //   // end : [ 'max' , threshold ],  
      //   start: ['min', 'max'],
      //   end: ['max', threshold],
      //   color : '#F4664A', 
      // },
      // {
      //   type : 'regionFilter', 
      //   top: true,
      //   // start : [ 'min' , threshold ],  
      //   // end : [ threshold , 'min' ],   
      //   start: ['min', threshold],
      //   end: ['max', 'min'],
      //   color : '#1890FF', 
      // },
      {
        type : 'line', 
        start : [ 'min' , threshold ],  
        end : [ 'max' , threshold ],  
        style : { 
          stroke : '#F4664A', 
          lineDash : [ 2 , 2 ],  
        },
      },
    ],
  } ;

  const configHumidity = { 
    ...config,
    yField : 'humidity',
    lineStyle: {
      stroke: '#1980FF',
      lineWidth: 1,
    },
    yAxis: {
      tickCount: 5,
      label: {
        formatter: (val: string | number) => {
          return (+val).toFixed(0) + '%';
        },
      },
      min: 0,
      max: 100,
    },
  } ;

  return (
    <Tabs defaultActiveKey="temp-tab" type="card">
      <TabPane tab="Temperature" key="temp-tab">
        {/* @ts-expect-error */}
        <Line {...configTemperature} />
      </TabPane>
      <TabPane tab="Humidity" key="hum-tab">
        <Line {...configHumidity} />
      </TabPane>
    </Tabs>
  );
};

SensorDataChart.propTypes = {
  sensorData: PropTypes.any.isRequired,
  type: PropTypes.string.isRequired,
};

export default SensorDataChart;