import { useState } from 'react';
import { Table, Form, Button, Input, Row, Col, Typography, message } from 'antd';
import { ColumnsType } from 'antd/es/table';
import PropTypes from 'prop-types';
import * as validators from '../../../data/utilities/validators';
import * as queries from '../../../data/api/queries';
import './NotificationsTable.css';

interface Props {
  orgParams: Record<string,any>,
  onSubmit: () => void,
  type: string,
}

const NotificationsTable: React.FC<Props> = (props) => {
  const { Text } = Typography;
  const { TextArea } = Input;
  const [form] = Form.useForm();

  const [orgParams, setOrgParams] = useState((props.orgParams));
  const [addedIdsValid, setAddedIdsValid] = useState(false);
  const [addInputStatus, setAddInpitStatus] = useState<'success'|'error'>('success');

  const processQuery = (data: string[], addOrRemove: 'add'|'remove') => {
    let query;
    let variables;
    if (props.type === 'reportEmail') {
      query = addOrRemove == 'add' ? queries.addReportEmailIdMutation : queries.removeReportEmailIdMutation;
      variables = { reportEmailDataInput: {reportEmail: data}};
    } else if (props.type === 'alertEmail') {
      query = addOrRemove == 'add' ? queries.addAlertEmailIdMutation : queries.removeAlertEmailIdMutation;
      variables = { alertEmailDataInput: {alertEmail: data}};
    } else {
      query = addOrRemove == 'add' ? queries.addTelegramChatIdMutation : queries.removeTelegramChatIdMutation;
      variables = { telegramChatIdDataInput: {chatId: data}};
    }
    const { headers } = queries;
    headers['Authorization'] = localStorage.getItem('Authorization') ?? '';

    form.resetFields();

    queries.sendQueryReturnObjObj(query, variables, headers)
    .then((data) => {
      if ('errors' in data) {
        props.onSubmit();
        if (data.errors.message) {
          message.error(data.errors.message);
        } else if (data.errors[0].message) {
          message.error(data.errors[0].message);
        } else {
          message.error('Data unavailable');
        }
      } else {
        let extension;
        if (props.type === 'reportEmail') {
          extension = addOrRemove == 'add' ? 'addReportEmailId' : 'removeReportEmailId';
        } else if (props.type === 'alertEmail') {
          extension = addOrRemove == 'add' ? 'addAlertEmailId' : 'removeAlertEmailId';
        } else {
          extension = addOrRemove == 'add' ? 'addTelegramChatId' : 'removeTelegramChatId';
        }
        setOrgParams(data.data[extension]);
        setAddedIdsValid(false);
        setHasSelected(false);
        localStorage.setItem('OrganisationDetails', JSON.stringify(data.data[extension]));
        const messageContent = addOrRemove == 'add' ? 'IDs were added successfully' : 'IDs were removed successfully';
        message.success(messageContent);
      }
    });
  }

  const onFinish = (ids: Record<string,string>) => {
    const idArray: string[] = ids['ids'].split(/(?:,| )+/).map((item: string) => {return item.trim()});
    processQuery(idArray, 'add');
  };

  const onChange = (e: any) => {
    const input = e.currentTarget.value;
    const isInputValid = props.type.includes('mail') ? validators.validateEmailIds(input) : validators.validateTelegramChatIDs(input);
    if(input.length > 0 && isInputValid) {
      setAddedIdsValid(true);
      setAddInpitStatus('success');
    } else if (input.length > 0 && !isInputValid) {
      setAddedIdsValid(false);
      setAddInpitStatus('error');
    } else {
      setAddedIdsValid(false);
      setAddInpitStatus('success');
    }
  };

  const [hasSelected, setHasSelected] = useState(false);
  const [selectedRows, setSelectedRows] = useState([{}]);
  const [selectedIds, setSelectedIds] = useState<string[]>([]);
  const rowSelection = {
    onChange: (selectedRowKeys: React.Key[], selectedRows: Record<string,string>[]) => {
      setHasSelected(selectedRows.length > 0);
      setSelectedRows(selectedRows);
      const idsToRemove: string[] = [];
      selectedRows.forEach((row) => {
        idsToRemove.push(row.id);
      })
      setSelectedIds(idsToRemove);
    },
  };
  
  const title = props.type.includes('mail') ? 'Email IDs' : 'Telegram Chat IDs';
  const typeOfIds = props.type.includes('mail') ? 'email' : 'Telegram';
  const typeOfNotificationsShort = props.type.includes('report') ? 'Report ' : 'Alert ';
  const typeOfNotifications = props.type.includes('report') ? 'daily reports.' : 'alerts in case a sensor reports abnormal temperature.';
  const placeholder = 'Enter ' + typeOfIds + ' ID(s) to add; use spaces or commas to separate multiple entries';
  const intro = typeOfNotificationsShort + title + ' are recipients of ' + typeOfNotifications;

  const onClickToRemove = () => {
    processQuery(selectedIds, 'remove');
  }

  const processRows = (rows: string[]) => {
    const formattedRow: { key: string; id: string; }[] = [];
    rows.forEach((row) => {
      const tempObject = {
        key: row,
        id: row,
      };
      formattedRow.push(tempObject);
    })
    return formattedRow;
  }

  const columns: ColumnsType<Record<string, string>> = [
    {
      title,
      dataIndex: 'id',
      render: (record: string) => {return <Text>{record}</Text>},
    },
  ]

  return (
    <>
      <Text>{intro}</Text>
      <br/><br/>
      <Form
        form={form}
        name='basic'
        onFinish={onFinish}
      >
        <Row>
          <Col flex="auto">
            <Form.Item
              name='ids'
              validateStatus={addInputStatus}
              // rules={[{ required: true, message: 'Please input ' + typeOfIds + ' IDs' }]}
            >
              <TextArea onChange={onChange} placeholder={placeholder} />
            </Form.Item>
          </Col>
          <Col flex="135px">
            <Row justify='end'>
            <Form.Item>
              {addedIdsValid ?
              <Button type='primary' htmlType='submit' className='btn-recipients'>
                Add recipients
              </Button> :
              <Button disabled htmlType='submit' className='btn-recipients'>
                Add recipients
              </Button>
              }
            </Form.Item>
            </Row>
          </Col>
        </Row>
      </Form>
      <Table
        pagination={{
          hideOnSinglePage: true,
        }}
        rowSelection={{
          ...rowSelection,
        }}
        columns={columns}
        dataSource={processRows(orgParams[props.type])}
      />
      <br />
      {hasSelected ? 
      <Button type="primary" block onClick={onClickToRemove}>
        Remove recipients
      </Button> :
      <Button block disabled>
        Remove recipients
      </Button>
      }
      
    </>
  );
};

NotificationsTable.propTypes = {
  orgParams: PropTypes.any.isRequired,
  onSubmit: PropTypes.func.isRequired,
  type: PropTypes.string.isRequired,
};

export default NotificationsTable;