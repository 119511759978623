
import React, { useState, useEffect } from 'react';
import { Table, Card, Button, Modal, Typography, Space, Row, Col, Badge, Tooltip, Tabs, Drawer, message } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { LineChartOutlined, ControlOutlined, UserAddOutlined, NotificationOutlined } from '@ant-design/icons';
import SensorDataCharts from '../../charts/SensorDataChart';
import SensorSettingsForm from '../../forms/SensorSettingsForm/SensorSettingsForm';
import AddUserForm from '../../forms/AddUserForm/AddUserForm';
import StateMarker from '../../markers/StateMarker/StateMarker';
import NotificationsTable from '../NotificationsTable/NotificationsTable';
import * as utilities from '../../../data/utilities/utilities';
import * as queries from '../../../data/api/queries';
import './FreezerSensorTable.css';
import { ColumnFilterItem } from 'antd/lib/table/interface';

const FreezerSensorTable: React.FC = () => {

  const { Title, Text } = Typography;
  const { TabPane } = Tabs;

  const[isAdmin] = useState(localStorage.getItem('Role') !== 'user');

  const [sensorInfoData, setSensorInfoData] = useState<Record<string, string>[]>([]);
  const orgName = localStorage.getItem('OrganisationName') ?? 'Unknown';
  const orgDetailsString = localStorage.getItem('OrganisationDetails');
  let orgDetails = {};
  if (orgDetailsString) {
    orgDetails = JSON.parse(orgDetailsString);
  } 

  const query = queries.getLatestQueryWithVars;
  const { headers } = queries;
  headers['Authorization'] = localStorage.getItem('Authorization') ?? '';
  const variables = {
    organisationId: localStorage.getItem('OrganisationId'),
  }
  const [isDownloading, setIsDownloading] = useState<boolean[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isLocationFilter, setLocationFilter] = useState(false);
  const [uniqueLocations, setUniqueLocations] = useState<ColumnFilterItem[]>([])
  
  useEffect(() => {
    setIsLoading(true);
    queries.sendQueryReturnObj(query, variables, headers)
    .then((data) => {
      if ('errors' in data) {
        setIsLoading(false);
        message.error('Data unavailable');
      } else {
        const dataSource = data.data.getLatest;
        const tempIsDownloadingArray: boolean[]  = [];
        dataSource.forEach((element, index) => {
          element.index = String(index);
          element.key = element["nodeId"];
          const timestamp = Number(element['measuredtime']);
          element['timestamp'] = utilities.convertTimestamp(timestamp);
          tempIsDownloadingArray.push(false);
        });
        setSensorInfoData(dataSource);
        const uniqueLocationsArr = dataSource.map((item) => {return item.location}).filter((value, index, self) => {return self.indexOf(value) === index});
        const locationsFilterArr: ColumnFilterItem[] = [];
        uniqueLocationsArr.sort().forEach((location) => {
          const tempLocation:ColumnFilterItem = {
            text: location,
            value: location,
          }
          locationsFilterArr.push(tempLocation);
        })
        if (uniqueLocationsArr.length <= 12) {
          setLocationFilter(true);
          setUniqueLocations(locationsFilterArr);
        }
        setIsDownloading(tempIsDownloadingArray);
        setIsLoading(false);
      }
    })
  } , []);

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isSettingsModalVisible, setIsSettingsModalVisible] = useState(false);
  const [isNewUserModalVisible, setIsNewUserModalVisible] = useState(false);
  const [isDrawerVisible, setDrawerVisible] = useState(false);

  const handleNewUserModalOk = () => {
    setIsNewUserModalVisible(false);
  };

  const handleNewUserModalCancel = () => {
    setIsNewUserModalVisible(false);
  };

  const showNewUserModal = () => {
    setIsNewUserModalVisible(true);
  }

  const [modalTitle, setModalTitle] = useState('Sensor data');
  const [sensorType, setSensorType] = useState('');
  const [modalData, setModalData] = useState<Record<string,Record<string, string>[]>>({});
  const [settingsData, setSettingsData] = useState<Record<string,string>>({});

  const showModal = (record:Record<string,string>) => {
    const downloadStatus = isDownloading;
    const index = Number(record.index);

    const query = queries.getSensorDataByIdByTimeQuery;
    const { headers } = queries;
    headers['Authorization'] = localStorage.getItem('Authorization') ?? '';
    const variables = {
      nodeId: record.nodeId,
      after: utilities.getTwoWeeksAgoTimestamp(),
    }
    let sensorReport;

    queries.sendQueryReturnObj(query, variables, headers)
    .then((data) => {
      if ('errors' in data || !data.data.getSensorDataByIdByTime) {
        message.error('Data unavailable');
      } else {
        sensorReport = data.data;
        setModalTitle('Sensor data for sensor ' + record.locationName + ' of type ' + record.locationType + ' at ' + record.location)
        setSensorType(record.locationType);
        setModalData(sensorReport)
        setIsModalVisible(true);
        downloadStatus[index] = false;
        setIsDownloading(downloadStatus);
      }
    })
  };

  const showSettingsModal = () => {
    setIsSettingsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const handleSettingsOk = () => {
    setIsSettingsModalVisible(false);
  };

  const handleSettingsCancel = () => {
    setIsSettingsModalVisible(false);
  };

  const showDrawer = () => {
    console.log(orgDetails);
    setDrawerVisible(true);
  };

  const onCloseDrawer = () => {
    setDrawerVisible(false);
  };

  const staticColumnsPrefix: ColumnsType<Record<string, string>> = [
    { 
      title: 'Sensor ID',
      dataIndex: 'nodeId',
      key: 'nodeId',
    },
    {
      title: 'Name',
      dataIndex: 'locationName',
      key: 'locationName',
      align: 'center',
      sorter: (a, b) => {
        return a.locationName.localeCompare(b.locationName);
      },
    },
  ]
  const locationNameColumn: ColumnsType<Record<string, string>> = isLocationFilter ? [
    {
      title: 'Location',
      dataIndex: 'location',
      key: 'location',
      align: 'center',
      filters: uniqueLocations,
      onFilter: (value, record) => {return record.location.indexOf(String(value)) === 0},
    },
  ] :
  [
    {
      title: 'Location',
      dataIndex: 'location',
      key: 'location',
      align: 'center',
      sorter: (a, b) => {
        return a.location.localeCompare(b.location);
      },
    },
  ]
  const staticColumns: ColumnsType<Record<string, string>> = [
    // { 
    //   title: 'Sensor ID',
    //   dataIndex: 'nodeId',
    //   key: 'nodeId',
    // },
    // {
    //   title: 'Name',
    //   dataIndex: 'locationName',
    //   key: 'locationName',
    //   align: 'center',
    //   sorter: (a, b) => {
    //     return a.locationName.localeCompare(b.locationName);
    //   },
    // },
    {
      title: 'Type',
      dataIndex: 'locationType',
      key: 'locationType',
      align: 'center',
      filters: [
        {
          text: 'Chiller',
          value: 'Chiller',
        },
        {
          text: 'Freezer',
          value: 'Freezer',
        },
        {
          text: 'Room',
          value: 'Room',
        },
      ],
      onFilter: (value, record) => {return record.locationType.indexOf(String(value)) === 0},
    },
    {
      title: 'T' + String.fromCharCode(176) + 'C',
      key: 'temperature',
      align: 'center',
      sorter: (a, b) => {
        const aNum = Number(a.temperature);
        const bNum = Number(b.temperature);
        return aNum - bNum;
      },
      render: (record:Record<string,string>) => {
        let type = 'success';
        if (Number(record.temperature) > Number(record.maxThreshold)) {
          type = 'danger'
        }
        return (
          <Space>
            <StateMarker type={type}/>
            <Text>{record.temperature}</Text>
          </Space>
        );
      },
    },
    {
      title: 'Humidity',
      dataIndex: 'humidity',
      key: 'humidity',
      align: 'center',
    },
    {
      title: 'Battery',
      dataIndex: 'batt',
      key: 'batt',
      align: 'center',
    },
    {
      title: 'Timestamp',
      dataIndex: 'timestamp',
      key: 'timestamp',
      align: 'center',
      sorter: (a, b) => {
        return a.timestamp.localeCompare(b.timestamp);
      },
    },
    {
      title: 'Alerts',
      dataIndex: 'alertEnabled',
      key: 'alertEnabled',
      align: 'center',
      render: (alertStatus:string) => {
        const alertStatusText = alertStatus == 'true' ? 'On' : 'Off';
        const alertStatusComponent = alertStatus == 'true' ? 
          <Tooltip placement="top" title={alertStatusText}><Badge dot><NotificationOutlined /></Badge></Tooltip> : 
          <Tooltip placement="top" title={alertStatusText}><NotificationOutlined style={{ color:'#ccc' }}/></Tooltip>;
        return alertStatusComponent;
      },
    },
    {
      title: 'Details',
      key: 'details',
      align: 'center',
      render: (record:Record<string,string>) => {
        const index = Number(record.index);
        return (
          <Space>
            <Tooltip placement="top" title='View temperature and humidity charts'>
              <Button
                loading={isDownloading[index]}
                key={record.nodeId + '-chart'}
                icon={<LineChartOutlined />}
                onClick={() => {
                  const downloadStatus = isDownloading;
                  const index = Number(record.index);
                  downloadStatus[index] = true;
                  setIsDownloading([...downloadStatus]);
                  return showModal(record);
                }}
              />
            </Tooltip>
            { isAdmin && 
              <Button
                key={record.nodeId+'-settings'}
                icon={<ControlOutlined />}
                onClick={() => {
                  setSettingsData(record);
                  return showSettingsModal();
                }}
              />
            }
          </Space>
        );
      },
    },
  ];

  const columns = [...staticColumnsPrefix,...locationNameColumn, ...staticColumns];
  return (
    <Card>
      <Row>
        <Col span={12}>
          <Title 
            level={5}
          >
            Temperature sensors for {orgName}
          </Title>
        </Col>
        <Col span={12}>
          <Row justify="end">
            { isAdmin &&
              <>
                <Button
                  key='btn-add-user'
                  type='primary'
                  className='margin-right'
                  icon={<UserAddOutlined />}
                  onClick={() => {
                    return showNewUserModal();
                  }}
                >
                  Add User
                </Button>
                <Button
                  key='btn-config-notifications'
                  type='primary'
                  icon={<NotificationOutlined />}
                  onClick={() => {
                    return showDrawer();
                  }}
                >
                  Configure notifications
                </Button>
                <br /><br />
              </>
            }
          </Row>
        </Col>
      </Row>
        <Table
          pagination={{
            hideOnSinglePage: true,
          }}
          scroll={{ x: '1000' }}
          key="sensor-table"
          dataSource={sensorInfoData} 
          columns={columns}
          size="small"
          loading={isLoading}
        />
      <Modal
        key="sensor-modal"
        style={{ top: 50 }}
        width={800}
        title={modalTitle}
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <SensorDataCharts sensorData={modalData} type={sensorType}/>
      </Modal>
      <Modal
        key="settings-modal"
        style={{ top: 50 }}
        width={400}
        title="Settings"
        visible={isSettingsModalVisible}
        footer={null}
        onOk={handleSettingsOk}
        onCancel={handleSettingsCancel}
      >
        <SensorSettingsForm sensorParams={settingsData} onSubmit={handleSettingsOk}/>
      </Modal>
      <Modal
        style={{ top: 50 }}
        width={400}
        title='Create new user'
        visible={isNewUserModalVisible}
        footer={null}
        onOk={handleNewUserModalOk}
        onCancel={handleNewUserModalCancel}
      >
        <AddUserForm onOk={handleNewUserModalOk}/>
      </Modal>
      <Drawer
        width={480}
        title="Notification Settings"
        placement="right"
        closable={true}
        onClose={onCloseDrawer}
        visible={isDrawerVisible}
      >
        <Tabs defaultActiveKey="1">
          <TabPane tab="Report Email IDs" key="1">
            <NotificationsTable orgParams={orgDetails} type='reportEmail' onSubmit={onCloseDrawer}/>
          </TabPane>
          <TabPane tab="Alert Email IDs" key="2">
            <NotificationsTable orgParams={orgDetails} type='alertEmail' onSubmit={onCloseDrawer}/>
          </TabPane>
          <TabPane tab="Alert Telegram Chat IDs" key="3">
            <NotificationsTable orgParams={orgDetails} type='alertTelegram' onSubmit={onCloseDrawer}/>
          </TabPane>
        </Tabs>
      </Drawer>
    </Card>
  );
};

export default FreezerSensorTable;