import { useState, useContext } from 'react';
import { Link, Switch, Route } from 'react-router-dom';
import { BarChartOutlined, LogoutOutlined } from '@ant-design/icons';
import { Layout, Menu } from "antd";
import AuthContext from '../../data/session/UserSession/UserSession';
import AdminLogo from '../../components/logos/AdminLogo/AdminLogo'
import routes from "../../routes";

import 'antd/dist/antd.css';
import './Admin.css';

const Base: React.FC = () => {
  const { Header, Footer, Sider, Content } = Layout;
  const authCtx = useContext(AuthContext);
  const { isLoggedIn } = authCtx;

  const switchRoutes = (
    <Switch>
      {routes.map((prop, key) => {
        if (prop.layout === '/admin' && isLoggedIn) {
          return (
            <Route
              path={prop.layout + prop.path}
              component={prop.component}
              key={key}
            />
          );
        }
      })}
    </Switch>
  );

  const [collapsed, setCollapsed] = useState(false);
  
  return (
    <Layout>
      <Sider
        breakpoint="lg"
        collapsedWidth="0"
        className='admin-sider'
        collapsible
        collapsed={collapsed}
        onCollapse={() => {return setCollapsed(!collapsed)}}
      >
        <AdminLogo />
        <Menu theme="dark" defaultSelectedKeys={["1"]} mode="inline">
          <Menu.Item key="1">
            <Link to="/admin/dashboard">
              <BarChartOutlined />
              <span>Dashboard</span>
            </Link>
          </Menu.Item>
          <Menu.Item key="3">
            <Link to="/login">
              <LogoutOutlined />
              <span>Logout</span>
            </Link>
          </Menu.Item> 
        </Menu>
      </Sider>
      <Layout>
          <Header className='admin-header' />
          <Content className='admin-content'>
            {switchRoutes}
          </Content>
          <Footer className='admin-footer' >Thermalink 2021</Footer>
      </Layout>
    </Layout>
  );
};

export default Base;