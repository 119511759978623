import InfoCard from '../../../components/cards/InfoCard/InfoCard';
import LoginForm from '../../../components/forms/LoginForm/LoginForm';

import './Login.css';

const Login: React.FC = () => {
  return (
    <InfoCard
      title='Login'
      type='default'
    >
      <LoginForm />
    </InfoCard>
  );
};

export default Login;