import { useState, useEffect } from 'react';
import { Form, Input, InputNumber, Button, Checkbox, message } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';
import PropTypes from 'prop-types';
import * as queries from '../../../data/api/queries';
import './SensorSettingsForm.css';

interface Props {
  sensorParams: Record<string,string>,
  onSubmit: () => void
}

const SensorSettingsForm: React.FC<Props> = (props) => {
  const [form] = Form.useForm();

  const [isAlertEnabled] = useState(props.sensorParams.alertEnabled == 'true');
  const [maxThreshold] = useState(parseInt(props.sensorParams.maxThreshold));
  const [locationName] = useState(props.sensorParams.locationName);
  const sensorDataInput = {
    sensorDataInput: {
      nodeId: props.sensorParams.nodeId,
      settings: {
        alertEnabled: props.sensorParams.alertEnabled,
        maxThreshold: props.sensorParams.maxThreshold,
      },
      location: {
        name: props.sensorParams.locationName,
      },
    },
  }

  const defaultValues = {
    name: props.sensorParams.locationName, threshold: parseInt(props.sensorParams.maxThreshold), alert:props.sensorParams.alertEnabled === 'true',
  }
  useEffect(() => {
    form.setFieldsValue(defaultValues)
   }, [form, defaultValues])

  const onFinish = (values: Record<string, any>) => {
    if (values.name !== locationName) {
      sensorDataInput.sensorDataInput.location.name = values.name;
    }
    if (values.threshold !== maxThreshold) {
      sensorDataInput.sensorDataInput.settings.maxThreshold = values.threshold;
    }
    if (values.alert !== isAlertEnabled) {
      sensorDataInput.sensorDataInput.settings.alertEnabled = values.alert.toString();
    }

    const query = queries.updateSensorMutation;
    const { headers } = queries;
    headers['Authorization'] = localStorage.getItem('Authorization') ?? '';
    const variables = sensorDataInput;
    console.log(variables);

    form.resetFields();

    queries.sendQueryReturnObjObj(query, variables, headers)
    .then((data) => {
      console.log(data);
      if ('errors' in data) {
        props.onSubmit();
        if (data.errors.message) {
          message.error(data.errors.message);
        } else if (data.errors[0].message) {
          message.error(data.errors[0].message);
        } else {
          message.error('Data unavailable');
        }
      } else {
        props.onSubmit();
        console.log(data.data);
        message.success('Settings were updated successfully');
      }
    })

  };

  return (
    <Form
      form={form}
      key={props.sensorParams.nodeId+'-settings-form'}
      className="settings-form"
      layout="vertical"
      name="basic"
      initialValues={defaultValues}
      onFinish={onFinish}
    >
      <Form.Item
        key={props.sensorParams.nodeId+'-name'}
        label="Storage name"
        name="name"
      >
        <Input
          name="name-input"
          key={props.sensorParams.nodeId+'-name-input'} 
        />
      </Form.Item>

      <Form.Item
        key={props.sensorParams.nodeId+'-threshold'}
        label="Permissible upper temperature limit"
        name="threshold"
        tooltip={{
          title: 'The upper limit that storage temperature should not exceed for more than a 30-minute period',
          icon: <InfoCircleOutlined />,
        }}
      >
        <InputNumber min={-50} max={50} />
      </Form.Item>

      <Form.Item name="alert" valuePropName="checked">
        <Checkbox>Alert enabled</Checkbox>
      </Form.Item>

      <Form.Item>
        <Button className="login-form-btn" type="primary" htmlType="submit">
          Update
        </Button>
      </Form.Item>
    </Form>
  );
};

SensorSettingsForm.propTypes = {
  sensorParams: PropTypes.any.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export default SensorSettingsForm;