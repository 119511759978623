// import { Link } from 'react-router-dom';
import './AdminLogo.css';
import logo from './tl-logo-text.png';

const AdminLogo: React.FC = () => {

  return (
    // <Link to="/">
      <img src={logo} className="admin-logo" alt="logo" />
    // </Link>
  );
};

export default AdminLogo;