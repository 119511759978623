import React, { useState, useEffect, useCallback } from 'react';

let logoutTimer: NodeJS.Timeout;

const AuthContext = React.createContext({
  token: '',
  isLoggedIn: false,
  role: 'user',
  login: (token: string, expirationTime: string, role: string, organisationId: string) => {
    // function body placeholder
  },
  logout: () => {
    // function body placeholder
  },
});

const calculateRemainingTime = (expirationTime: string | 0 ) => {
  const currentTime = new Date().getTime();
  const adjExpirationTime = new Date(expirationTime).getTime();

  const remainingDuration = adjExpirationTime - currentTime;

  return remainingDuration;
};

const retrieveStoredToken = () => {
  const storedToken = localStorage.getItem('Token');
  
  const storedExpirationDate = localStorage.getItem('ExpirationTime') || 0 ;

  const remainingTime = calculateRemainingTime(storedExpirationDate);

  if (remainingTime <= 3600) {
    localStorage.removeItem('Token');
    localStorage.removeItem('Authorization');
    localStorage.removeItem('ExpirationTime');
    localStorage.removeItem('Role');
    localStorage.removeItem('OrganisationId');
    return null;
  }

  return {
    token: storedToken ?? '',
    duration: remainingTime,
  };
};

interface AuthContextProviderProps {
  children?: React.ReactNode,
}

export const AuthContextProvider = ({ children }: AuthContextProviderProps):JSX.Element => {
  const tokenData = retrieveStoredToken();
  
  let initialToken;
  if (tokenData) {
    initialToken = tokenData.token;
  }

  const [token, setToken] = useState(initialToken);
  
  const userIsLoggedIn = !!token;

  const logoutHandler = useCallback(() => {
    console.log('Logging out');
    setToken('');
    localStorage.removeItem('Token');
    localStorage.removeItem('Authorization');
    localStorage.removeItem('ExpirationTime');
    localStorage.removeItem('Role');
    localStorage.removeItem('OrganisationId');

    if (logoutTimer) {
      clearTimeout(logoutTimer);
    }
  }, []);

  const loginHandler = (token: string, expirationTime: string, role: string, organisationId: string) => {
    setToken(token);
    localStorage.setItem('Token', token);
    const bearer = 'Bearer ' + token;
    localStorage.setItem('Authorization', bearer);
    localStorage.setItem('ExpirationTime', expirationTime);
    localStorage.setItem('Role', role);
    localStorage.setItem('OrganisationId', organisationId);

    const remainingTime = calculateRemainingTime(expirationTime);

    logoutTimer = setTimeout(logoutHandler, remainingTime);
  };

  useEffect(() => {
    if (tokenData) {
      console.log(tokenData.duration);
      logoutTimer = setTimeout(logoutHandler, tokenData.duration);
    }
  }, [tokenData, logoutHandler]);

  const contextValue = {
    token: token ?? '',
    organisationId: '',
    role: 'user',
    isLoggedIn: userIsLoggedIn,
    login: loginHandler,
    logout: logoutHandler,
  };

  return (
    <AuthContext.Provider value={contextValue}>
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;